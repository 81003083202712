import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong, faGlobe, faLocation, faPhone, faEnvelope, faArrowAltCircleRight, faInfoCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF, faGooglePlusG, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import footerBg from "../../images/footer-bg.jpg";

function Footer() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    // src\images\footer-bg.jpg
    const backgroundImageUrl = {
        backgroundImage: `url(${footerBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    // const backgroundImageUrl = `url("https://i.ibb.co/sCX4N6s/footer-bg.jpg")`;
    let quickLinks = [
        {
            linkname: "Home",
            href: "/",
        },
        {
            linkname: "About",
            href: "/about",
        },
        {
            linkname: "Contact",
            href: "/contact",
        },
    ];
    let Services = [
        {
            linkname: "Website Development",
            href: "/WebsiteDevelopmentDesigning",
        },
        {
            linkname: "Mobile Application Development",
            href: "/MobileApplicationDevelopment",
        },
        {
            linkname: "Content Writing",
            href: "/ContentWriting",
        },
        {
            linkname: "Social media marketing",
            href: "/SocialMediaMarketing",
        },
        {
            linkname: "Digital Marketing",
            href: "/DigitalMarketing",
        },
        {
            linkname: "Web hosting services",
            href: "/WebHostingServices",
        },
        {
            linkname: "Web design",
            href: "/WebDesign",
        },
        {
            linkname: "Graphic Design",
            href: "/GraphicDesign",
        },
    ];
    let Follow = [
        {
            linkname: "47 Walters Road, Blacktown NSW-2148",
            icon: faLocation,
            href: "https://maps.app.goo.gl/p22sN6d5XLyLQ83VA",
        },
        {
            linkname: "Level 3, 169 Fullarton Road, Dulwich, Adelaide, South Australia SA 5065",
            icon: faLocation,
            href: "https://maps.app.goo.gl/4WeFZZTHqsj3uuEAA",
        },
        {
            linkname: "3 & 4/40 Brookhollow Avenue, Baulkham Hills, NSW - 2153",
            icon: faLocation,
            href: "https://maps.app.goo.gl/suAJTDN4YZwz5p3s9",
        },
        {
            linkname: "+61 468 720 642",
            icon: faPhone,
            href: "tel:+61 468 720 642",
        },
        {
            linkname: "+61 870 941 934",
            icon: faPhone,
            href: "tel:+61 870 941 934",
        },
        {
            linkname: "info@ozvaservice.com.au",
            icon: faEnvelope,
            href: "mailto: info@ozvaservice.com.au",
        },
        {
            linkname: "ABN: 65 666 642 299",
            icon: faQuestionCircle,
            href: "tel:65 666 642 299",
        },
    ];

    return (
        <>
            {/* <div className="">
                <div className="footer_rounded"></div>
                <div className="footer_rounded_bottom">
                    <Container>
                        <Row className="align-items-center py-3 pb-5">
                            <Col md={8} className="mb-md-0 mb-3">
                                <p className="text-white mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, expedita, saepe, vero rerum deleniti beatae veniam harum neque nemo praesentium cum alias asperiores commodi.</p>
                            </Col>
                            <Col md={4}>
                            <Button variant="light" className="w-100 py-2 text-primary bg-white fs-5"> Contact Us </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div> */}
            <section
                className="footer_section"
                style={backgroundImageUrl}
            >

                <Container>
                    <Row>
                        <Col lg={3} sm={6} className="mb-lg-0 mb-4">
                            <h6 className="text-white fw-semibold mb-4">About Us</h6>
                            <p className="footer_content ">
                                At OZVASERVICE, we specialise in providing cutting-edge IT. We offer various solutions and services to cater to the different requirements of our customers. Clients.
                            </p>
                            <div className="d-flex align-items-center gap-3 flex-warp">

                                <div className="polygon-shape d-flex align-items-center justify-content-center faFacebookF ">
                                    <a target="_blank" href="https://www.facebook.com/ozvaservice/">
                                        <FontAwesomeIcon target="_blank" icon={faFacebookF} className="footer_icon" />
                                    </a>
                                </div>
                                {/* <div className="polygon-shape d-flex align-items-center justify-content-center  faTwitter">
                                    <a target="_blank" href="https://twitter.com/OzvaServic">
                                        <FontAwesomeIcon target="_blank" icon={faTwitter} className="footer_icon" />
                                    </a>
                                </div> */}
                                <div className="polygon-shape d-flex align-items-center justify-content-center  faInstagram">
                                    <a target="_blank" href="https://www.instagram.com/ozvaservice/">
                                        <FontAwesomeIcon target="_blank" icon={faInstagram} className="footer_icon" /></a>
                                </div>
                                {/* <div className="polygon-shape d-flex align-items-center justify-content-center  faGooglePlusG">
                                    <FontAwesomeIcon icon={faGooglePlusG} className="footer_icon" />
                                </div>
                                <div className="polygon-shape d-flex align-items-center justify-content-center  faGlobe">
                                    <FontAwesomeIcon icon={faGlobe} className="footer_icon" />
                                </div> */}
                            </div>

                        </Col>
                        <Col lg={3} sm={6} className="footer_content mb-lg-0 mb-4">
                            <h6 className="text-white fw-semibold mb-4">Quick links</h6>
                            {quickLinks.map((el, i) => {
                                return (
                                    <div className="d-flex align-items-center gap-2 mb-2" key={i}>
                                        <FontAwesomeIcon
                                            icon={faRightLong}
                                            className="text-white"
                                            style={{ fontSize: "14px" }}
                                        />
                                        <Link onClick={scrollToTop} to={el.href}>{el.linkname}</Link>
                                        {/* {el} */}
                                    </div>
                                );
                            })}
                        </Col>
                        <Col lg={3} sm={6} className="footer_content mb-lg-0 mb-4">
                            <h6 className="text-white fw-semibold mb-4">Our Services</h6>
                            {Services.map((el, i) => {
                                return (
                                    <div className="d-flex align-items-center gap-2 mb-2" key={i}>
                                        <FontAwesomeIcon
                                            icon={faRightLong}
                                            className="text-white"
                                            style={{ fontSize: "14px" }}
                                        />
                                        <Link onClick={scrollToTop} to={el.href}>{el.linkname}</Link>
                                        {/* {el} */}
                                    </div>
                                );
                            })}
                        </Col>
                        <Col lg={3} sm={6} className="mb-lg-0 mb-4">
                            <h6 className="text-white fw-semibold mb-4 ">Follow us</h6>

                            {Follow.map((el, i) => {
                                return (
                                    <div className="d-flex gap-3" key={i}>
                                        <FontAwesomeIcon
                                            icon={el.icon}
                                            className="text-primary mt-2"
                                        />
                                        <div className="footer_content">
                                            <Link onClick={scrollToTop} target="_blank" to={el.href}>{el.linkname}</Link>
                                            <p style={{ fontSize: "14px" }} className="mb-2" >
                                                {el.title}
                                                <a href={el.link} target="_blank" className="text-primary">
                                                    {el.link}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <hr className="text-white" />
                    <p className="text-center footer_content">
                        © {" "}
                        <span className="text-primary">OZVASERVICE.</span> All Rights Reserved
                    </p>
                </Container>
            </section>
        </>
    );
}

export default Footer;
